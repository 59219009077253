<template>
  <div v-if="workout != null" class="deckslide">

    <div class="deckslide__header">
      <div class="deckslide__header__left">
        <img @click="goBack"  src="@/assets/ic_nav_close_white.svg" alt="">
      </div>
      <div class="deckslide__header__center">
        <!-- TITLE -->
      </div>
      <div class="deckslide__header__right">
        <img @click="shareWorkout" src="@/assets/ic_nav_share_white.svg" alt="">
        <img v-if="isFavourite" @click="removeWorkoutLike" src="@/assets/ic_nav_star-active.svg" alt="">
        <img v-else @click="likeWorkout" src="@/assets/ic_nav_star_white.svg" alt="">
      </div>
    </div>
    
    <!-- COVER -->
    <div class="deckslide__cover">
        <img v-if="coach" :src="'https://app.sportlerplus.com/scaled/800/' + coach.avatar_image_url" alt="" />
        <h1 class="text-that-needs-wrapping">{{ workout.title }}</h1>
        
        <!-- TODO @Marco: Add class ".expanded" if user has clicked on the cta below -->
        <p>{{ workout.intro }}</p>

        <!-- TODO @Marco: Should be only visible if line clamp is active -->
        <div class="deckslide__cover__moretext">
            Alles anzeigen
        </div>
    </div>

    <!-- CONTENT -->
    <div class="deckslide__content">

        <!-- META -->
        <div class="list list--workoutmeta">
            <!-- META | DAUER -->
            <div class="list__item">
                <div class="list__item__icon">
                    <span class="list__item__icon__number">
                        {{ workout.duration }}'
                    </span>
                    <img src="@/assets/ic_32_hexagon_outline_white.svg" alt="" />
                </div>
                <div class="list__item__content">
                    <div class="list__item__label list__item__label--white">
                        Dauer
                    </div>
                    <div class="list__item__value list__item__value--white">
                        {{ workout.duration }} Minuten
                    </div>
                </div>
            </div>
            <!-- META | INTENSITÄT -->
            <div class="list__item">
                <div class="list__item__icon">
                    <img v-if="workout.difficulty == 3" src="@/assets/ic_32_workout_intensity-high.svg" alt="" />
                    <img v-else-if="workout.difficulty == 1" src="@/assets/ic_32_workout_intensity-low.svg" alt="" />
                    <img v-else src="@/assets/ic_32_workout_intensity-medium.svg" alt="" />
                </div>
                <div class="list__item__content">
                    <div class="list__item__label list__item__label--white">
                        Intensität
                    </div>
                    <div class="list__item__value list__item__value--white">
                        {{ workoutIntensities[workout.difficulty-1].name }}
                    </div>
                </div>
            </div>
            <!-- META | EQUIPMENT -->
            <div class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_32_workout_equipment.svg" alt="" />
                </div>
                <div class="list__item__content">
                    <div class="list__item__label list__item__label--white">
                        Equipment
                    </div>
                    <div v-if="workout.requires_equipment" class="list__item__value list__item__value--white ">
                        {{ equipmentListAsString }}
                    </div>
                    <div v-else class="list__item__value list__item__value--white">
                        Nein
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- BUTTON -->
    <div class="deckslide__button">
        <v-btn @click="showWarmupModal" class="button button--primary"> {{ getButtonText() }}</v-btn>
    </div>

    <!-- BG IMAGE -->
    <div class="deckslide__background">
        <img max-width="100%" :src="'https://app.sportlerplus.com/scaled/800/' + workout.preview_image_url" />
    </div>

    <!-- WARMUP MODAL -->
    <WarmupModal @closeDialog="startWorkout"></WarmupModal>
  </div>
</template>

<script>
import axios from 'axios';
import WarmupModal from '@/components/WarmupModal.vue';
import {analytics} from '@/firebase/index.js';
import { disallowHistoryBack } from '@/router/index.js';
import { sharePage } from '@/sportlerplus-helper';

export default {
    name: 'WorkoutDetail',
    data() {
        return {
            workout: null,
            isFavourite: false,
            coach: null,
        }
    },
    methods: {
        async init(workoutId){
            let apiUrl = this.$store.getters.getApiUrl;
            await axios
            .get(apiUrl + 'pages/' + workoutId + "/")
            .then(response => {
                this.workout = response.data;
                let coaches = this.$store.getters.getCoaches;
                if (this.workout.coach) {
                    this.coach = coaches.find(c => c.id == this.workout.coach.id);
                }
                this.$store.dispatch('checkArticleFavourite', this.workout.id).then((res) => {
                    this.isFavourite = res;
                });
            });
            // if(this.$route.query.back) {
            //     console.log(this.$route.query.back);
            //     this.showWarmupModal();
            // }
        },
        startWorkout() {
            let workoutSource = {uid: "dummy_uid", completedExercises: [], workoutVideo: this.workout,
                share_url: this.getShareUrl(),
                coach: this.coach, startDate: new Date()};
            if (this.$route.query.guid) {
                workoutSource.fitnessPlanEvent = {
                    guid : this.$route.query.guid,
                    activityDate : this.$route.query.activityDate,
                    activityTime : this.$route.query.activityTime,
                    _realStartDate: new Date(),
                }
            }
            this.$store.dispatch('initWorkoutSource', workoutSource);
            analytics.logEvent('start_workout', {
                workout_id: this.workout.id,
            });
            this.$store.commit('addRecentTrainingActivity', this.workout);
            this.$router.push("/workout/video/start");
        },
        goBack() {
            if ((window.history.length > 1) && !disallowHistoryBack) {
                this.$router.go(-1);
            }
            else {
                this.$router.push("/trainieren");
            }
        },
        likeWorkout() {
            this.isFavourite = true;
            this.$store.dispatch('likeWorkout', this.workout);
        },
        shareWorkout() {
            let shareText = "Hey, ich habe gerade dieses Workout in der SportlerPlus-App entdeckt: „{0}“\n\nProbiere es direkt aus! 💪🚀".format(this.workout.title);
            sharePage(this.getShareUrl(), null, shareText, null);
        },
        removeWorkoutLike() {
            this.isFavourite = false;
            /* removeArticleLike does not do any workout specific stuff */
            this.$store.dispatch('removeArticleLike', this.workout);
        },
        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        showWarmupModal() {
            if (!this.$route.query.back === true && !this.workout.category_list.includes(11)) {
                if (this.$route.query.guid) {
                    window.localStorage.setItem('guid', this.$route.query.guid);
                    window.localStorage.setItem('activityDate', this.$route.query.activityDate);
                    window.localStorage.setItem('activityTime', this.$route.query.activityTime);
                }
                else {
                    window.localStorage.removeItem('guid');
                    window.localStorage.removeItem('activityDate');
                    window.localStorage.removeItem('activityTime');
                }
                this.$modal.show("warmupHint");
            }
            else {
                this.startWorkout();
            }
        },
        getShareUrl() {
            return "{0}/sharing{1}".format(window.location.origin, window.location.pathname);
        },
        getButtonText() {
            if (this.workout.category_list.includes(11)) {
                return "Warmup starten";
            } else {
                return "Workout starten";
            }
        }
    },
    components: {
        WarmupModal
    },
    computed: {
        equipmentListAsString() {
            var equipmentStringList = [];
            var equipmentList = this.$store.getters.getEquipmentList;
            this.workout.equipment.forEach(e => {
                var equipmentName = equipmentList.find(el => el.id == e.id);
                if (equipmentName) {
                    equipmentStringList.push(equipmentName.name)
                }
            });
            if (equipmentStringList.length == 0) { return "Nein"; }
            return equipmentStringList.join(", ");
        },
        workoutIntensities() {
            return this.$store.getters.getWorkoutIntensities;
        }
    },
    mounted() {
        let workoutId = this.$route.params.id;
        if (this.isNumeric(workoutId)) {
            this.init(workoutId);
        }
        else {
            let apiUrl = this.$store.getters.getApiUrl;
            axios
                .get(apiUrl + 'pages?slug=' + workoutId)
                .then(response => {
                    let searchResult = response.data;
                    if (searchResult.items && searchResult.items.length > 0) {
                        workoutId = searchResult.items[0].id;
                        this.init(workoutId);
                    }
                });
        }
    }
}
</script>