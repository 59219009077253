<template>
    <VModal name="warmupHint" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
            </div>
            <div class="vm-header-right">
                <img @click="dismissDialog" src="@/assets/ic_nav_close.svg" alt="" style="visibility: hidden;">
            </div>
        </div>
        <div class="vm-content">
            <img src="@/assets/notification/ic_notification_warmup.svg" alt="">
            <h3>
                Bist du schon warm?
            </h3>
            <p>
                Unsere Workouts starten direkt mit der Belastung. Daher empfehlen wir vorab ein Warmup-Workout durchzuführen.
            </p>
            <v-btn @click="dismissDialog" type="button" class="button button--primary button--small">Ja, Workout starten</v-btn>
            <v-btn @click="showWarmupWorkouts" type="button" class="button button--secondary button--small" >Zu den Warmup-Workouts</v-btn>
        </div>
    </VModal>
</template>

<script>
export default {
    name: 'WarmupModal',
    props: {
        message: {
            type: String,
        },
    },
    methods: {
        dismissDialog() {
            this.$modal.hide('warmupHint');
            this.$emit("closeDialog");
        },
        showWarmupWorkouts() {
            window.localStorage.setItem('originWorkoutId', this.$route.params.id);
            this.$router.push('/workouts?warmup=1');
        }
    }
}
</script>

<style lang="scss" scoped>
    .vm-content {
        padding-top: 0;

        img {
            margin-top: 0;
        }
    }
</style>